.container-module .container-menu-item-header {
padding:var(--spacing-s) var(--spacing-sm);font-weight:var(--font-semibold);border-top-left-radius:6px;border-top-right-radius:6px
}

.container-module .container-menu-v2-item-header {
padding:var(--spacing-md) var(--spacing-sm);background-color:var(--color-gray50)
}

.container-module .container-menu-context-switch-header {
padding:0 0 var(--spacing-sm) 0
}

.container-module .width-auto {
width:auto
}

