.container-module .menu-subitem .internal-link,.container-module .menu-subitem .external-link {
display:flex;align-items:center;min-width:fit-content;padding:var(--spacing-md) var(--spacing-sm);color:inherit;text-decoration:none
}

.container-module .menu-subitem .internal-link {
justify-content:space-between
}

.container-module .menu-subitem .external-link .ac-hyperlink-text,.container-module .menu-subitem .external-link .ac-icon-external-link {
color:var(--color-black)
}

.container-module .menu-subitem .external-link .ac-hyperlink-text.sc-ac-hyperlink {
text-decoration:none
}

.container-module .menu-subitem .external-link a {
width:100%
}

