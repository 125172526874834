.container-module .login-logout-page .login-logout-image {
display:none
}

.container-module .login-logout-page .login-logout-body {
box-sizing:border-box;width:100vw;height:100dvh;padding:var(--spacing-xxl) var(--spacing-xlg);background-color:var(--color-white)
}

.container-module .login-logout-page .login-logout-body .branding-logo {
width:fit-content;max-height:52px
}

@media(min-width: 768px) {
.container-module .login-logout-page .login-logout-image {
display:block;width:100vw;height:100dvh;object-fit:cover;object-position:center
}

.container-module .login-logout-page .login-logout-body {
position:absolute;top:50%;left:50%;width:352px;height:fit-content;padding:var(--spacing-lg);border-radius:8px;box-shadow:var(--shadow-xlg);transform:translate(-50%, -50%)
}


}

@media(min-width: 1921px) {
.container-module .login-logout-page .login-logout-image {
background-image:url("./assets/aurora-lg.jpg")
}


}

