.container-module .menu-item-hidden {
visibility:visible
}

.container-module .menu-bar-wrapper {
background-color:var(--menu-bar-color)
}

.container-module .menu-bar-wrapper .menu-bar {
position:relative;z-index:var(--z-index-main-menu);flex-direction:row;width:calc(100vw - 52px);height:var(--menu-height);padding-inline-start:var(--spacing-xs);padding-top:0;background-color:var(--menu-bar-color)
}

.container-module .menu-bar-wrapper .menu-bar .items-container {
flex-direction:row;width:100%;overflow:hidden
}

.container-module .menu-bar-wrapper .menu-bar .container-menu-button {
min-width:115px
}

.container-module .menu-bar-wrapper .menu-bar.without-property {
width:calc(100vw - 48px)
}

.container-module .menu-bar-wrapper .menu-bar.full-width {
width:100vw
}

.container-module .selectable-list-dropdown-wrapper {
margin-top:0
}

.container-module .list-header {
width:100%
}

.container-module .list-header-gray {
width:100%;background-color:var(--color-gray50)
}

.container-module .container-banner {
display:flex;flex:0 0 40px
}

