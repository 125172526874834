.container-module .menu-subitems-wrapper .ac-infotip-content {
font-size:var(--font-size-md)
}

.container-module .menu-subitems-container {
z-index:var(--z-index-select-main-menu)
}

.container-module .menu-subitems-container.rounded-none {
border-radius:0
}

.container-module .menu-subitems-container .main-menu-infotip-body .menu-subitem .internal-link {
padding:var(--spacing-sm) var(--spacing-sm)
}

