.container-module .menu-subitem {
cursor:pointer
}

.container-module .menu-subitem .subitem-shortcut {
display:flex;align-items:center;min-width:50px;padding:0;margin:0;font-size:var(--font-size-sm);text-align:right
}

.container-module .menu-subitem .subitem-shortcut.not-selected {
color:var(--color-gray500)
}

.container-module .menu-subitem.subitem-selected {
color:var(--color-white);background-color:var(--color-primary-action)
}

.container-module .menu-subitem:hover {
color:var(--color-white);background-color:var(--color-blue700)
}

.container-module .menu-subitem.menu-subitem-v2:first-of-type {
border-top-left-radius:6px;border-top-right-radius:6px
}

.container-module .menu-subitem.menu-subitem-v2:last-of-type {
border-bottom-right-radius:6px;border-bottom-left-radius:6px
}

.container-module .menu-subitem.menu-subitem-v2.subitem-selected {
color:var(--color-black);background-color:var(--color-primary-light)
}

.container-module .menu-subitem.menu-subitem-v2:hover {
color:var(--color-black);background-color:var(--color-primary-light)
}

.container-module .menu-subitem.menu-subitem-v2:hover .subitem-shortcut {
color:var(--color-gray500)
}

.container-module .menu-subitem:hover .subitem-shortcut {
color:var(--color-white)
}

.container-module .menu-subitem .menu-subitem-link {
text-decoration:none
}

