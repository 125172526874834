.container-module .menu-infotip-status-wrapper .status-icon-wrapper {
position:absolute;top:4px;right:5px;width:var(--icon-size-lg);height:var(--icon-size-lg);border-radius:50%
}

.container-module .menu-infotip-status-wrapper .status-icon-wrapper.status-wrapper-active {
justify-content:center;background-color:var(--color-success)
}

.container-module .menu-infotip-status-wrapper .status-icon-wrapper.status-wrapper-alert {
justify-content:center;background-color:var(--color-alert)
}

.container-module .menu-infotip-status-wrapper .status-icon-wrapper.status-wrapper-gray {
justify-content:center;background-color:var(--color-gray400)
}

.container-module .menu-infotip-status-wrapper .status-icon-wrapper .status-icon {
display:flex;align-items:center
}

.container-module .menu-infotip-status-wrapper .status-icon-wrapper .status-icon::before {
font-size:var(--font-size-sm)
}

