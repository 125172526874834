@media print {
html.container-module,.container-module body {
--sp-context-help-width:0
}

.container-module #container-module {
display:none
}

.container-module #application-container>.ember-view:not(.ac-position-wrapper-addon-base) {
height:auto
}


}

